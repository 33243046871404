body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Adjusting the left setting of the element displaying dropdown items to allow full view. This is primarily adjusted for the in-meeting-tab*/
.ms-Dropdown-callout {
    left: 0px !important;
}